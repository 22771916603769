import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface CombinedControlsProps {
  label: string;
  color: string;
  setColor: (color: string) => void;
  modelOption: string;
  setModelOption: (option: string) => void;
  modelOptions: { value: string; label: string; description: string }[];
  onOptionChange: (label: string, optionLabel: string, description: string) => void;
}

const CombinedControls: React.FC<CombinedControlsProps> = ({
  label,
  color,
  setColor,
  modelOption,
  setModelOption,
  modelOptions,
  onOptionChange,
}) => {
  const handleOptionChange = (option: string, optionLabel: string, description: string) => {
    setModelOption(option);
    onOptionChange(label, optionLabel, description);
  };

  return (
    <Container>
      <Row>
        <Col className="text-top">
          <label htmlFor={`${label.toLowerCase()}Color`} className="form-label">
            {label}
          </label>
          <div className="d-flex flex-column align-items-start">
            <input
              type="color"
              className="form-control form-control-color mb-3"
              id={`${label.toLowerCase()}Color`}
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <div className="d-flex flex-column">
              {modelOptions.map((option) => (
                <div className="form-check mb-2" key={option.value}>
                  <input
                    type="radio"
                    className="form-check-input"
                    name={`${label.toLowerCase()}ModelOption`}
                    id={option.value}
                    value={option.value}
                    checked={modelOption === option.value}
                    onChange={() => handleOptionChange(option.value, option.label, option.description)}
                  />
                  <label htmlFor={option.value} className="form-check-label">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CombinedControls;
