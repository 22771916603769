import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";

interface VesselControlsProps {
  autoRotate: boolean;
  setAutoRotate: (value: boolean) => void;
  showWireframe: boolean;
  setShowWireframe: (value: boolean) => void;
  handleDownload: () => void;
}

const VesselControls: React.FC<VesselControlsProps> = ({
  autoRotate,
  setAutoRotate,
  showWireframe,
  setShowWireframe,
  handleDownload,
}) => {
  return (
    <Container className="p-3 bg-light rounded">
      <Row className="mb-3">
        <Col>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="autoRotateCheckbox"
              checked={autoRotate}
              onChange={() => setAutoRotate(!autoRotate)}
            />
            <label className="form-check-label" htmlFor="autoRotateCheckbox">
              Enable Rotation
            </label>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="showWireframeCheckbox"
              checked={showWireframe}
              onChange={() => setShowWireframe(!showWireframe)}
            />
            <label className="form-check-label" htmlFor="showWireframeCheckbox">
              Show Wireframe
            </label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={handleDownload}>
            Download Model
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default VesselControls;
