import React from "react";
import { useSubmarineContext } from "./Calculation/SubmarineContext";
import ClassDisplay from "../ClassDisplay";
import useHullCalculator from "./Calculation/HullCalculator";

const Hull: React.FC = () => {
  const { submarineState } = useHullCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.hull?.weightValue?.toFixed(2) || "N/A",
      equation:
        "minorWeight + internalHullStructureWeight + compartmentBulkHeadWeight + dispMarginWeight + trimmingWaterWeight",
      parameters: [
        {
          label: "Minor Weight",
          value: submarineState.hull?.minorWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Trimming Water Weight",
          value: submarineState.hull?.trimmingWaterWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Compartment Bulkhead Weight",
          value:
            submarineState.hull?.compartmentBulkHeadWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Internal Hull Structure Weight",
          value:
            submarineState.hull?.internalHullStructureWeight?.toFixed(2) ||
            "N/A",
        },
        {
          label: "Main Ballast Tank Weight",
          value:
            submarineState.hull?.mainBallastTankWeight?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.hull?.volume?.toFixed(2) || "N/A",
      equation: "steelVolume + trimmingWaterVolume + mainBallastTankVolume",
      parameters: [
        {
          label: "Trimming Water Volume",
          value: submarineState.hull?.trimmingWaterVolume?.toFixed(2) || "N/A",
        },
        {
          label: "Main Ballast Tank Volume",
          value:
            submarineState.hull?.mainBallastTankVolume?.toFixed(2) || "N/A",
        },
        {
          label: "Steel Volume",
          value: submarineState.hull?.steelVolume?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Cost",
      value: submarineState.hull?.cost?.toFixed(2) || "N/A",
      equation: "100000 * steelVolumeValue / 1000000 + steelVolumeValue",
      parameters: [
        {
          label: "Steel volume value",
          value: submarineState.hull.steelVolume.toFixed(2) || "N/A",
        },
      ],
    },
  ];

  return <ClassDisplay title="Hull Structure" sections={sections} />;
};

export default Hull;
