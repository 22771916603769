import React from "react";
import { Alert } from "react-bootstrap";

interface DescriptionBoxProps {
  name?: string;
  description?: string;
  units?: string;
}

const DescriptionBox: React.FC<DescriptionBoxProps> = ({
  name,
  description,
  units,
}) => {
  // Use defaults when values are blank (empty string or null/undefined)
  const displayName = name && name.trim() !== "" ? name : "No field selected";
  const displayDescription =
    description && description.trim() !== ""
      ? description
      : "Select a field to see a description.";

  return (
    <Alert variant="info" className="mt-4">
      <strong>{displayName}</strong> {units ? `(${units})` : ""} :{" "}
      {displayDescription}
    </Alert>
  );
};

export default DescriptionBox;
