import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const useHullCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const { crewNum, deckNum, deckHeight, verticalLaunchSilos, divingDepth } =
    submarineState.inputs;

  const calculateHull = () => {
    let displacement = 2356 * Math.log(crewNum) - 6250;
    const normDisplacement = displacement * 0.8;
    const diameter = deckNum * deckHeight * 2.1;

    const length =
      (4 *
        (displacement / 1.024 -
          ((1 / 3) *
            Math.PI *
            (((diameter / 2) ** 2 +
              (diameter / 2) ** 2 * 0.2 +
              ((diameter / 2) * 0.2) ** 2) *
              0.15 *
              diameter) -
            0.5 * Math.PI * (diameter / 2) ** 3))) /
        (diameter ** 2 * Math.PI) +
      1.8 * verticalLaunchSilos;
    const lengthDiameterRatio = length / diameter;

    const minorWeight =
      divingDepth < 400
        ? (10 ** -9 * divingDepth ** 3 -
            8 * 10 ** -7 * divingDepth ** 2 +
            0.0004 * divingDepth +
            0.0936) *
          1.03 *
          1.15 *
          normDisplacement
        : (0.08 + (0.05 / 100) * divingDepth) * 1.03 * 1.15 * normDisplacement;

    const trimmingWaterWeight = 0.007 * normDisplacement;
    const trimmingWaterVolume = trimmingWaterWeight / 1.025;
    const compartmentBulkHeadWeight = 0.023 * normDisplacement;
    const internalHullStructureWeight = 0.026 * normDisplacement;
    const mainBallastTankWeight = 0.1 * normDisplacement;
    const mainBallastTankVolume = mainBallastTankWeight / 1.025;
    const dispMarginWeight = 0.04 * normDisplacement;

    let weightValue =
      minorWeight +
      internalHullStructureWeight +
      compartmentBulkHeadWeight +
      dispMarginWeight +
      trimmingWaterWeight;

    const permanentBallast = calculatePermanentBallast(
      displacement,
      submarineState.systems.totalWeight
    );
    weightValue += permanentBallast;

    const thickness = (1.15 * weightValue) / (8 * Math.PI * diameter * length);
    const steelVolume =
      Math.PI *
        ((diameter / 2) ** 2 - (diameter / 2 - thickness) ** 2) *
        (length - diameter) +
      ((4 * Math.PI) / 3) *
        ((diameter / 2) ** 3 - (diameter / 2 - thickness) ** 3);

    const materialCostPerCubicMeter = 1500;
    let fabricationComplexityMultiplier = 1.0 + 0.05 * verticalLaunchSilos;

    //const bulkheadCost = compartmentBulkHeadWeight * 500;
    //const internalStructureCost = internalHullStructureWeight * 800;
    //const mainBallastCost = mainBallastTankWeight * 300;

    // const baseCost = steelVolume * materialCostPerCubicMeter;
    let cost = (100000 * steelVolume) / 1000000 + steelVolume;

    weightValue = weightValue * 1.2; // artificial sclaing to approximate original
    cost = cost * 0.8; // artificial sclaing to approximate original

    const volume = steelVolume + trimmingWaterVolume + mainBallastTankVolume;

    setSubmarineState((prevState) => ({
      ...prevState,
      hull: {
        displacement,
        normDisplacement,
        diameter,
        length,
        lengthDiameterRatio,
        minorWeight,
        steelVolume,
        trimmingWaterWeight,
        trimmingWaterVolume,
        compartmentBulkHeadWeight,
        internalHullStructureWeight,
        mainBallastTankWeight,
        mainBallastTankVolume,
        permanentBallast,
        dispMarginWeight,
        thickness,
        cost,
        volume,
        fabricationComplexityMultiplier,
        weightValue,
      },
    }));
  };

  const calculatePermanentBallast = (
    displacement = submarineState.hull.displacement,
    initialWeight = submarineState.totals.weight,
    tolerance = 10000,
    maxIterations = 1
  ) => {
    let weight = initialWeight;
    let ballast = 64;
    let iteration = 0;

    while (iteration < maxIterations) {
      const diff = displacement - weight;

      if (Math.abs(diff) <= tolerance) {
        break;
      }

      if (diff > 0) {
        ballast += diff * 0.8;
        weight += ballast;
      } else {
        ballast -= Math.abs(diff) * 0.2;
        weight += ballast;
      }

      iteration++;
    }

    return Math.max(ballast, 0);
  };

  useEffect(() => {
    calculateHull();
  }, [submarineState.inputs, submarineState.hull, setSubmarineState]);

  return { submarineState, calculateHull };
};

export default useHullCalculator;
