import React from "react";
import { Card, ListGroup, Stack } from "react-bootstrap";

type Parameter = {
  label: string;
  value: string | number | null;
};

type SectionConfig = {
  title: string;
  value: string | number;
  parameters: Parameter[];
  equation?: string;
};

type ClassDisplayProps = {
  title?: string;
  sections: SectionConfig[];
};

const ClassDisplay: React.FC<ClassDisplayProps> = ({ title, sections }) => {
  // Utility to format numbers to 2 decimal places
  const formatValue = (value: string | number | null) => {
    if (value === null || value === undefined) return "N/A";
    if (typeof value === "number") return value.toFixed(2);
    return value;
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        maxHeight: "70vh", // Constrain height to 70% of the viewport
        overflowY: "auto", // Add vertical scrolling if content exceeds height
      }}
    >
      {/* Title Section */}
      {title && (
        <Card.Header className="text-center">
          <h4>{title}</h4>
        </Card.Header>
      )}

      <Card.Body>
        <Stack gap={4}>
          {/* Sections */}
          {sections.map((section, index) => (
            <Card
              key={index}
              className="p-3 shadow-sm"
              style={{
                backgroundColor: `hsl(${index * 40}, 70%, 90%)`, // Generate distinct colors for each card
                borderRadius: "10px",
              }}
            >
              <Card.Header
                style={{
                  backgroundColor: `hsl(${index * 40}, 70%, 80%)`,
                  borderRadius: "8px",
                }}
              >
                <h5 className="mb-0 text-dark">
                  {section.title} ({formatValue(section.value)})
                </h5>
                <p className="mb-0 text-muted small">
                  {section.equation && `(${section.equation})`}
                </p>
              </Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  {section.parameters.map((param, paramIndex) => (
                    <ListGroup.Item
                      key={paramIndex}
                      className="py-1 border-0 bg-transparent"
                    >
                      <strong>{param.label}:</strong> {formatValue(param.value)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          ))}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default ClassDisplay;
