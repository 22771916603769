import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CollapsibleContainer from '../../components/CollapsibleContainer';
import missionProfilesData from './MissionProfiles.json';

// Define the structure of a mission profile
interface MissionProfileData {
  id: string;
  title: string;
  description: string[];
  map: string;
}

// Main component to manage the mission profiles section
const MissionProfiles = () => {
  // State to track the currently active tab
  const [activeTab, setActiveTab] = useState<string>(missionProfilesData[0].id);

  return (
      <div>
        <MissionProfilesTabs
          missionProfiles={missionProfilesData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <MissionProfilesContent
          missionProfiles={missionProfilesData}
          activeTab={activeTab}
        />
      </div>
  );
};

// Header component for the mission profiles section
const MissionProfilesHeader = () => (
  <div className="bg-light p-5 rounded mb-4">
    <p className="lead">
      This page is intended to list the mission profiles that will drive the vessel parameters. It's purely informational, it does not interact with the data model.
    </p>
  </div>
);

// Props for the MissionProfilesTabs component
interface MissionProfilesTabsProps {
  missionProfiles: MissionProfileData[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

// Component to render the navigation tabs for mission profiles
const MissionProfilesTabs: React.FC<MissionProfilesTabsProps> = ({
  missionProfiles,
  activeTab,
  setActiveTab,
}) => (
  <ul className="nav nav-tabs mb-4">
    {missionProfiles.map((profile) => (
      <li className="nav-item" key={profile.id}>
        <a
          className={`nav-link ${activeTab === profile.id ? 'active' : ''}`}
          onClick={() => setActiveTab(profile.id)}
          href="#"
        >
          {profile.title}
        </a>
      </li>
    ))}
  </ul>
);

// Props for the MissionProfilesContent component
interface MissionProfilesContentProps {
  missionProfiles: MissionProfileData[];
  activeTab: string;
}

// Component to display the content of the currently active mission profile
const MissionProfilesContent: React.FC<MissionProfilesContentProps> = ({
  missionProfiles,
  activeTab,
}) => (
  <div className="tab-content">
    {missionProfiles.map((profile) =>
      activeTab === profile.id ? (
        <MissionProfile key={profile.id} profile={profile} />
      ) : null
    )}
  </div>
);

// Props for the MissionProfile component
interface MissionProfileProps {
  profile: MissionProfileData;
}

// Component to display the details of a specific mission profile
const MissionProfile: React.FC<MissionProfileProps> = ({ profile }) => {
  // Construct the path to the mission profile map image
  const imagePath = `${process.env.PUBLIC_URL}/assets/${profile.map}`;

  return (
    <div id={profile.id} className="mb-5">
      <div className="row g-4 align-items-center">
        <div className="col-lg-6">
          <h3 className="fw-bold">{profile.title}</h3>
          {profile.description.map((paragraph, index) => (
            <p key={index} className="mb-3">
              {paragraph}
            </p>
          ))}
        </div>
        <div className="col-lg-6 text-center">
          <img
            src={getAssetPath(profile.map)}
            alt={profile.title}
            className="img-fluid rounded shadow"
          />
        </div>
      </div>
    </div>
  );
};

// Utility function to get the path for an asset, with error handling
const getAssetPath = (assetName: string) => {
  try {
    return require(`./${assetName}`).default || require(`./${assetName}`);
  } catch (error) {
    console.error(`Error loading asset: ${assetName}`, error);
    return `${process.env.PUBLIC_URL}/assets/placeholder.png`;
  }
};

export default MissionProfiles;
