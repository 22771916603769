import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const useBatteryCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const calculateBattery = () => {
    const { crewNum, indiscretionRatio, maxSubmergedSpeed } =
      submarineState.inputs;
    const { length, diameter, lengthDiameterRatio } = submarineState.hull;
    const { hotelLoad } = submarineState.propulsion;
    const patrolSpeed = submarineState.propulsion.patrolSpeed;

    // Ensure lengthDiameterRatio is calculated
    const calculatedLengthDiameterRatio = length / diameter;

    // Drag Coefficient Calculation using lengthDiameterRatioValue (matches VB logic)
    let dragCoefficient: number;
    if (calculatedLengthDiameterRatio < 6) {
      dragCoefficient = 10.5 / (calculatedLengthDiameterRatio + 3);
    } else {
      dragCoefficient = -22 / (calculatedLengthDiameterRatio - 25);
    }

    // Admiralty Coefficient Calculation using maxSubmergedSpeedValue (matches VB logic)
    const admiraltyValue = 22 * maxSubmergedSpeed - 102;

    // Patrol Power Calculation (matches VB logic)
    const patrolPower =
      ((patrolSpeed ** 3 * (2356 * Math.log(crewNum + 1) - 6300) ** (2 / 3)) /
        admiraltyValue) *
      dragCoefficient *
      0.8;

    // Battery Life Calculation
    const batteryLife = 8 * (100 / indiscretionRatio - 1);

    // Energy Demand Calculation
    const energyValue = batteryLife * 2 * patrolPower * 3600;

    // Battery Count Calculation (matches VB logic, removed division by 24)
    let batteryCount = (1.2 * energyValue) / 180000;
    if (batteryCount < 10) {
      batteryCount = 10;
    }

    // Weight Calculation
    const weight = (215 * batteryCount) / 1000;

    // Volume Calculation
    const volume = 0.133 * weight + batteryCount / 2;

    // Cost Calculation
    const cost = 0.0075 * batteryCount;

    // Submerged Endurance Calculation (optional, if needed)
    const submergedEndurance =
      (batteryCount * 180000) / ((patrolPower + hotelLoad) * 3600);

    // Update State
    setSubmarineState((prevState) => ({
      ...prevState,
      batteries: {
        weight,
        volume,
        cost,
        batteryCount,
        submergedEndurance,
        energyValue,
        batteryLife,
        patrolPower,
        dragCoefficient,
        admiraltyValue,
      },
    }));
  };

  useEffect(() => {
    calculateBattery();
  }, [
    submarineState.inputs,
    submarineState.hull,
    submarineState.propulsion,
    setSubmarineState,
  ]);

  return { submarineState, calculateBattery };
};

export default useBatteryCalculator;
