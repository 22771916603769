import React, { useEffect } from "react";
import { Card, Row, Container, Col } from "react-bootstrap";
import { useSubmarineContext } from "./SubmarineContext";
import DebugFieldsArray from "../../DebugFieldArray";

const Totals = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const weightArray: [string, number][] = [
    ["Hull", submarineState.hull.minorWeight],
    ["Propulsion", submarineState.propulsion.weight],
    ["Systems", submarineState.systems.weight],
    ["Batteries", submarineState.batteries.weight],
    ["Torpedos", submarineState.torpedos.weight],
    ["Cruise Missiles", submarineState.cruiseMissiles.weight],
  ];

  const volumeArray: [string, number][] = [
    ["Fuel and Oil", submarineState.propulsion.fuelAndOilVolume],
    ["Diesel Plant", submarineState.propulsion.dieselPlantVolume],
    ["Electric Motor", submarineState.propulsion.electricMotorVolume],
    ["Batteries", submarineState.batteries.volume],
    ["Torpedos", submarineState.torpedos.volume],
    ["Cruise Missiles", submarineState.cruiseMissiles.volume],
  ];

  const costArray: [string, number][] = [
    ["Hull", submarineState.hull.cost],
    ["Propulsion", submarineState.propulsion.cost],
    ["Systems", submarineState.systems.cost],
    ["Batteries", submarineState.batteries.cost],
    ["Torpedos", submarineState.torpedos.cost],
    ["Cruise Missiles", submarineState.cruiseMissiles.cost],
  ];

  const calculateTotal = () => {
    // Helper function to calculate total from an array
    const sumArray = (array: [string, number][]) =>
      array.reduce((sum, [, value]) => sum + value, 0);

    // Calculate totals
    const weight = sumArray(weightArray);
    const volume = sumArray(volumeArray);
    const cost = sumArray(costArray);

    // Update submarine state with totals
    setSubmarineState((prevState) => ({
      ...prevState,
      totals: {
        weight,
        volume,
        cost,
      },
    }));
  };

  useEffect(() => {
    calculateTotal();
  }, [submarineState.hull, setSubmarineState]);

  // Define consistent colors for datasets
  const colors = {
    weight: "hsl(0, 70%, 90%)", // Red for weight
    volume: "hsl(40, 70%, 90%)", // Green for volume
    cost: "hsl(80, 70%, 90%)", // Blue for cost
  };

  const borderColors = {
    weight: "hsl(0, 70%, 80%)",
    volume: "hsl(40, 70%, 80%)",
    cost: "hsl(80, 70%, 80%)",
  };

  return (
    <Container>
      <Row>
        <Col>
          <DebugFieldsArray
            label="Weight"
            dataArray={weightArray}
            unit="Tonnes"
            backgroundColor={colors.weight}
            borderColor={borderColors.weight}
          />
        </Col>

        <Col>
          <DebugFieldsArray
            label="Volume"
            dataArray={volumeArray}
            unit="m^3"
            backgroundColor={colors.volume}
            borderColor={borderColors.volume}
          />
        </Col>

        <Col>
          <DebugFieldsArray
            label="Cost"
            dataArray={costArray}
            unit="$"
            backgroundColor={colors.cost}
            borderColor={borderColors.cost}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Totals;
