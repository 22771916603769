import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from "react";

// Define the initial state structure for a single submarine
const initialSubmarineState = {
  inputs: {
    name: "New Submarine",
    crewNum: 60,
    divingDepth: 250,
    maxSubmergedSpeed: 18,
    torpedoTubes: 6,
    verticalLaunchSilos: 0,
    transitToPatrolDays: 20,
    transitToPatrolDistance: 7000,
    transitPatrolDays: 20,
    transitPatrolDistance: 2500,
    transitBackToBaseDays: 20,
    transitBackToBaseDistance: 7000,
    deckHeight: 2,
    deckNum: 2,
    indiscretionRatio: 25,
  },
  hull: {
    lengthDiameterRatio: 0,
    length: 10,
    diameter: 10,
    minorWeight: 0,
    volume: 0,
    displacement: 0,
    normDisplacement: 0,
    mainBallastTankWeight: 0,
    permanentBallast: 0,
    mainBallastTankVolume: 0,
    trimmingWaterWeight: 0,
    trimmingWaterVolume: 0,
    compartmentBulkHeadWeight: 0,
    internalHullStructureWeight: 0,
    steelVolume: 0,
    dispMarginWeight: 0,
    thickness: 0,
    cost: 0,
    fabricationComplexityMultiplier: 0,
    weightValue: 0,
  },
  propulsion: {
    maxPower: 0,
    patrolPower: 0,
    submergedEndurance: 0,
    volume: 0,
    cost: 0,
    dieselPlantVolume: 0,
    electricMotorVolume: 0,
    fuelAndOilVolume: 0,
    fuelAndOilWeight: 0,
    shaftingWeight: 0,
    foundationsWeight: 0,
    dieselEngineWeight: 0,
    electricPropulsionWeight: 0,
    hotelLoad: 0,
    averageSpeed: 0,
    patrolSpeed: 0,
    weight: 0,
  },
  systems: {
    weight: 0,
    volume: 0,
    cost: 0,
    complementAndStocksVolume: 0,
    electricEquipmentWeight: 0,
    accommodationVolume: 0,
    stocksComplementWeight: 0,
    gearSystemWeight: 0,
    furnitureWeight: 0,
    enduranceDays: 10,
    enduranceDistance: 10,
    submergedTime: 10,
    totalCost: 10,
    totalWeight: 0,
    totalVolume: 0,
  },
  cruiseMissiles: {
    weight: 0,
    volume: 0,
    cost: 0,
    missileCount: 0,
    siloSupport: 0,
    plateStructure: 0,
    capStructure: 0,
    numSilo: 0,
    volumeBase: 0,
    volumeOffset: 0,
  },
  torpedos: {
    baseWeight: 0,
    weight: 0,
    volume: 0,
    cost: 0,
    totalTorpedoes: 0,
    structuralWeight: 0,
    miscellaneousWeight: 0,
    volumeBase: 0,
    volumeOffset: 0,
    costBase: 2,
    costPerTube: 2,
  },
  batteries: {
    weight: 0,
    volume: 0,
    cost: 0,
    batteryCount: 0,
    submergedEndurance: 0,
    energyValue: 0,
  },
  totals: {
    weight: 0,
    volume: 0,
    cost: 0,
  },
};

// Extend the context type to also include submarines
const SubmarineContext = createContext<{
  submarineState: typeof initialSubmarineState;
  setSubmarineState: React.Dispatch<
    React.SetStateAction<typeof initialSubmarineState>
  >;
  submarines: (typeof initialSubmarineState)[];
  setSubmarines: React.Dispatch<
    React.SetStateAction<(typeof initialSubmarineState)[]>
  >;
}>({
  submarineState: initialSubmarineState,
  setSubmarineState: () => {},
  submarines: [],
  setSubmarines: () => {},
});

// Create a specific context for length and diameter
const LengthDiameterContext = createContext<{
  length: number;
  diameter: number;
}>({
  length: initialSubmarineState.hull.length,
  diameter: initialSubmarineState.hull.diameter,
});

interface SubmarineProviderProps {
  children: ReactNode;
}

export const SubmarineProvider: React.FC<SubmarineProviderProps> = ({
  children,
}) => {
  const [submarineState, setSubmarineState] = useState(initialSubmarineState);

  // New state for multiple submarines
  const [submarines, setSubmarines] = useState<
    (typeof initialSubmarineState)[]
  >([]);

  const value = useMemo(
    () => ({ submarineState, setSubmarineState, submarines, setSubmarines }),
    [submarineState, submarines]
  );

  const lengthDiameterValue = useMemo(
    () => ({
      length: submarineState.hull.length,
      diameter: submarineState.hull.diameter,
    }),
    [submarineState.hull.length, submarineState.hull.diameter]
  );

  return (
    <SubmarineContext.Provider value={value}>
      <LengthDiameterContext.Provider value={lengthDiameterValue}>
        {children}
      </LengthDiameterContext.Provider>
    </SubmarineContext.Provider>
  );
};

// Create custom hooks to access the contexts
export const useSubmarineContext = () => useContext(SubmarineContext);
export const useLengthDiameterContext = () => useContext(LengthDiameterContext);
