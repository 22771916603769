import React from "react";
import ClassDisplay from "../ClassDisplay";
import useCruiseCalculator from "./Calculation/CruiseMissileCalculator";

const CruiseMissile: React.FC = () => {
  const { submarineState } = useCruiseCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.cruiseMissiles?.weight?.toFixed(2) || "N/A",
      equation:
        "  (siloSupport + plateStructure + 0.2 + 1.25 * capStructure + 1.4 + 0.5) + plateStructure",
      parameters: [
        {
          label: "Silo Support",
          value:
            submarineState.cruiseMissiles?.siloSupport?.toFixed(2) || "N/A",
        },
        {
          label: "Plate Structure",
          value:
            submarineState.cruiseMissiles?.plateStructure?.toFixed(2) || "N/A",
        },
        {
          label: "Cap Structure",
          value:
            submarineState.cruiseMissiles?.capStructure?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.cruiseMissiles?.volume?.toFixed(2) || "N/A",
      equation: "(Math.PI * diameter ** 2) / 4 * (0.75 * numSilo + 1)",
      parameters: [
        {
          label: "Diameter",
          value: submarineState.hull?.diameter?.toFixed(2) || "N/A",
        },
        {
          label: "numSilo",
          value: submarineState.cruiseMissiles?.numSilo?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Cost",
      value: submarineState.cruiseMissiles?.cost?.toFixed(2) || "N/A",
      equation:
        "((siloSupport + plateStructure + capStructure) * total silos + plateStructure + 3.5 + 2 * total silos)",
      parameters: [
        {
          label: "siloSupport",
          value:
            submarineState.cruiseMissiles?.siloSupport?.toFixed(2) || "N/A",
        },
        {
          label: "plateStructure",
          value:
            submarineState.cruiseMissiles?.plateStructure.toFixed(2) || "N/A",
        },
        {
          label: "capStructure",
          value:
            submarineState.cruiseMissiles?.capStructure.toFixed(2) || "N/A",
        },
        {
          label: "total silos",
          value: submarineState.cruiseMissiles?.numSilo.toFixed(2) || "N/A",
        },
      ],
    },
  ];

  return <ClassDisplay title="Cruise Missiles" sections={sections} />;
};

export default CruiseMissile;
