import React from "react";
import { useSubmarineContext } from "./Calculation/SubmarineContext";
import ClassDisplay from "../ClassDisplay";
import useBatteryCalculator from "./Calculation/BatteryCalculator";

const Battery: React.FC = () => {
  const { submarineState } = useBatteryCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.batteries?.weight?.toFixed(2) || "N/A",
      equation: "(215 * batteryCount) / 1000",
      parameters: [
        {
          label: "Battery Count",
          value: submarineState.batteries?.batteryCount || "N/A",
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.batteries?.volume?.toFixed(2) || "N/A",
      equation: "0.133 * weight + batteryCount / 2",
      parameters: [
        {
          label: "Weight",
          value: submarineState.batteries?.weight || "N/A",
        },
        {
          label: "Battery Count",
          value: submarineState.batteries?.batteryCount || "N/A",
        },
      ],
    },
    {
      title: "Cost",
      value: submarineState.batteries?.cost?.toFixed(2) || "N/A",
      equation: "0.0075 * batteryCount",
      parameters: [
        {
          label: "Battery Count",
          value: submarineState.batteries?.batteryCount || "N/A",
        },
      ],
    },
    {
      title: "Battery Count",
      value: submarineState.batteries?.batteryCount,
      equation:
        "1.2 * (batterylifeValue * 2 * patrolPowerValue * 60 * 60)) / 180000",
      parameters: [
        {
          label: "battery Life Value",
          value: submarineState.batteries?.submergedEndurance || "N/A",
        },
        {
          label: "Patrol power",
          value: submarineState.propulsion.maxPower || "N/A",
        },
      ],
    },
  ];

  return <ClassDisplay title="Battery System" sections={sections} />;
};

export default Battery;
