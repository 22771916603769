import React from "react";
import useTorpedoCalculator from "./Calculation/TorpedoCalculator";
import ClassDisplay from "../ClassDisplay";

const Torpedo: React.FC = () => {
  const { submarineState } = useTorpedoCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.torpedos?.weight?.toFixed(2) || "N/A",
      equation: "(1.2×Tubes×8)+(1.2+0.4+1.5)×Tubes×3×1.5+20",
      parameters: [
        { label: "Base Weight", value: submarineState.torpedos?.baseWeight },
        {
          label: "tubes",
          value: submarineState.inputs.torpedoTubes,
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.torpedos?.volume?.toFixed(2) || "N/A",
      equation: "124.86×Tubes+Offset",
      parameters: [
        { label: "Tubes", value: submarineState.inputs.torpedoTubes },
        { label: "Offset", value: submarineState.torpedos.volumeOffset },
      ],
    },
    {
      title: "Cost",
      value: submarineState.torpedos?.cost?.toFixed(2) || "N/A",
      equation: "costBase + costPerTube * torpedoTubes",
      parameters: [
        { label: "Base Cost", value: submarineState.torpedos?.costBase },
        { label: "Cost Per Tube", value: submarineState.torpedos?.costPerTube },
        { label: "Torpedo Tubes", value: submarineState.inputs.torpedoTubes },
      ],
    },
  ];

  return <ClassDisplay title="Torpedos" sections={sections} />;
};

export default Torpedo;
