import React from "react";
import { useSubmarineContext } from "./Calculation/SubmarineContext";
import ClassDisplay from "../ClassDisplay";
import useSystemsCalculator from "./Calculation/SystemsCalculator";

const Systems: React.FC = () => {
  const { submarineState } = useSystemsCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.systems?.weight?.toFixed(2) || "N/A",
      equation:
        "0.05 * normDisplacement + gearSystemWeight + stocksComplementWeight + furnitureWeight",
      parameters: [
        {
          label: "Norm Displacement",
          value: submarineState.hull?.displacement?.toFixed(2) || "N/A",
        },
        {
          label: "Gear System Weight",
          value: submarineState.systems?.gearSystemWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Stocks Complement Weight",
          value:
            submarineState.systems?.stocksComplementWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Furniture Weight",
          value: submarineState.systems?.furnitureWeight?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.systems?.volume?.toFixed(2) || "N/A",
      equation: "complementAndStocksVolume + accommodationVolume",
      parameters: [
        {
          label: "Complement and Stocks Volume",
          value:
            submarineState.systems?.complementAndStocksVolume?.toFixed(2) ||
            "N/A",
        },
        {
          label: "Accommodation Volume",
          value:
            submarineState.systems?.accommodationVolume?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Cost",
      value: submarineState.systems?.totalCost?.toFixed(2) || "N/A",
      equation: "weight * 0.3",
      parameters: [
        {
          label: "Weight",
          value: submarineState.systems?.weight?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Endurance",
      value: submarineState.systems?.enduranceDays?.toFixed(2) || "N/A",
      equation:
        "transitToPatrolDays + transitPatrolDays + transitBackToBaseDays",
      parameters: [
        {
          label: "Transit to Patrol",
          value:
            submarineState.inputs?.transitToPatrolDays?.toFixed(2) || "N/A",
        },
        {
          label: "Patrol Transit",
          value: submarineState.inputs?.transitPatrolDays?.toFixed(2) || "N/A",
        },
        {
          label: "Transit Back to Base",
          value:
            submarineState.inputs?.transitBackToBaseDays?.toFixed(2) || "N/A",
        },
      ],
    },
  ];

  return <ClassDisplay title="Systems Overview" sections={sections} />;
};

export default Systems;
