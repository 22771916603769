import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import InputField from "../../components/InputField";
import DescriptionBox from "../../components/DescriptionBox";
import { useSubmarineContext } from "../../components/Submarine/Calculation/SubmarineContext";
import resultsSummary from "./ReportDefinitions/resultsSummary.json";
import weightReport from "./ReportDefinitions/weightReport.json";
import volumeReport from "./ReportDefinitions/volumeReport.json";
import dimensionsPerformanceReport from "./ReportDefinitions/dimensionsAndPerformanceReport.json";
import costReport from "./ReportDefinitions/costReport.json";

// Configuration mapping for different reports
const configMap = {
  weightReport: { config: weightReport, title: "Weights" },
  volumeReport: { config: volumeReport, title: "Volume" },
  dimensionsPerformanceReport: {
    config: dimensionsPerformanceReport,
    title: "Dimensions Performance",
  },
  costReport: { config: costReport, title: "Costing" },
};

const VesselResults: React.FC = () => {
  const { submarineState } = useSubmarineContext();
  const [activeTab, setActiveTab] = useState("cost");
  const [descriptionData, setDescriptionData] = useState<{
    name: string;
    description: string;
    units?: string;
  }>({
    name: "",
    description: "",
    units: undefined,
  });

  const handleInputClick = (field: any) => {
    setDescriptionData({
      name: field.label,
      description: field.tooltip || "No description available.",
      units: field.unit,
    });
  };

  const renderFields = (config: any[]) => {
    if (!config || !Array.isArray(config)) {
      return <p>No fields to display.</p>;
    }

    return (
      <Form>
        {config.map((field) => {
          // Access the corresponding section from submarineState
          const section =
            submarineState[field.class as keyof typeof submarineState];
          const value = section[field.id as keyof typeof section];

          return (
            <InputField
              key={field.id}
              id={field.id}
              label={field.label}
              value={value || ""}
              tooltip={field.tooltip}
              unit={field.unit}
              readOnly={true}
              handleInputChange={() => {}} // No changes for read-only fields
              handleInputClick={() => handleInputClick(field)}
            />
          );
        })}
      </Form>
    );
  };

  const renderTabContent = (config: any[]) => (
    <Row className="mt-3">{renderFields(config)}</Row>
  );

  return (
    <Container>
      <Col style={{ padding: "20px", height: "85vh", overflowY: "scroll" }}>
        {/* Always Visible Description */}
        <DescriptionBox
          name={descriptionData.name}
          description={descriptionData.description}
          units={descriptionData.units}
        />

        {/* Main Content */}
        <Row className="mb-4">
          <Form.Label>Results</Form.Label>
          {renderFields(resultsSummary)}
        </Row>

        {/* Tabs for Additional Content */}
        <Row>
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k || "cost")}
          >
            {Object.entries(configMap).map(([key, { config, title }]) => (
              <Tab eventKey={key} title={title} key={key}>
                {renderTabContent(config)}
              </Tab>
            ))}
          </Tabs>
        </Row>
      </Col>
    </Container>
  );
};

export default VesselResults;
