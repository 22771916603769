import React from "react";
import { Bar } from "react-chartjs-2";

// Define the props for the component
interface DebugFieldsArrayProps {
  dataArray: Array<[string, number]>; // 2D array with name and value pairs
  label: string; // Label for the dataset
  unit: string; // Unit for the total (e.g., "Tonnes", "m^3", "$")
  backgroundColor?: string; // Background color for the bars
  borderColor?: string; // Border color for the bars
}

// Functional component to display data details as a bar chart
const DebugFieldsArray: React.FC<DebugFieldsArrayProps> = ({
  dataArray,
  label,
  unit,
  backgroundColor = "rgba(75, 192, 192, 0.6)", // Default background color
  borderColor = "rgba(75, 192, 192, 1)", // Default border color
}) => {
  // Prepare data for the bar chart
  const labels = dataArray.map(([label]) => label);
  const dataValues = dataArray.map(([, value]) => value);

  const total = dataValues.reduce((sum, value) => sum + value, 0);

  const data = {
    labels,
    datasets: [
      {
        label: `${label} (${unit})`,
        data: dataValues,
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y" as const, // Horizontal bar chart
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: `${label} (${unit})`,
        },
      },
      y: {
        title: {
          display: true,
          text: "Components",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <h3>
        Total {label}: {total.toFixed(2)} {unit}
      </h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DebugFieldsArray;
