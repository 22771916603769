import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const useSystemsCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const calculateSystems = () => {
    const { crewNum, deckNum } = submarineState.inputs;
    const { normDisplacement } = submarineState.hull;

    // Endurance Calculations
    const enduranceDays =
      submarineState.inputs.transitToPatrolDays +
      submarineState.inputs.transitPatrolDays +
      submarineState.inputs.transitBackToBaseDays;
    const submergedTime = submarineState.inputs.transitPatrolDays;
    const enduranceDistance =
      submarineState.inputs.transitToPatrolDistance +
      submarineState.inputs.transitPatrolDistance +
      submarineState.inputs.transitBackToBaseDistance;

    // Room Calculations
    const numberOfRooms = Math.ceil((crewNum - 5) / 8);

    // Complement and Stocks Volume Calculation
    const complementAndStocksVolume =
      deckNum === 3
        ? 12 * crewNum +
          5 * 1.15 * 0.004 * crewNum * enduranceDays +
          (20 * crewNum * enduranceDays) / 1000 +
          (65 * crewNum * submergedTime) / (24 * 1000)
        : 6 * crewNum +
          5 * 1.15 * 0.004 * crewNum * enduranceDays +
          (20 * crewNum * enduranceDays) / 1000 +
          (65 * crewNum * submergedTime) / (24 * 1000);

    // Accommodation Volume Calculation
    const accommodationVolume =
      1.9 * 2 * 2 * 5 +
      3.83 * 1.8 * 2 * numberOfRooms +
      3.91 * 1.9 * 2 * (numberOfRooms + 2);

    // Weight Calculations
    const electricEquipmentWeight = 0.1 * normDisplacement;
    const stocksComplementWeight =
      ((110 + (3.5 + 6) * enduranceDays) * crewNum) / 1000;
    const displacementInThousands = normDisplacement / 1000;
    const gearSystemWeight =
      (-9e-13 * displacementInThousands ** 3 +
        1e-8 * displacementInThousands ** 2 -
        5e-5 * displacementInThousands +
        0.1954) *
      normDisplacement;
    const furnitureWeight = 0.08 * normDisplacement;

    // Total Weight and Volume
    const weight =
      0.05 * normDisplacement +
      gearSystemWeight +
      stocksComplementWeight +
      furnitureWeight;
    const volume = complementAndStocksVolume + accommodationVolume;

    // Cost Calculations
    const cost = weight * 0.3;
    const totalCost =
      submarineState.hull.cost +
      submarineState.propulsion.cost +
      submarineState.torpedos.cost +
      submarineState.cruiseMissiles.cost +
      cost;
    const totalWeight =
      submarineState.hull.minorWeight +
      submarineState.propulsion.dieselEngineWeight +
      submarineState.propulsion.shaftingWeight +
      submarineState.propulsion.fuelAndOilVolume +
      submarineState.batteries.weight +
      submarineState.hull.mainBallastTankWeight +
      submarineState.propulsion.electricPropulsionWeight +
      submarineState.torpedos.weight +
      submarineState.cruiseMissiles.weight +
      weight +
      748 +
      submarineState.hull.permanentBallast;
    const totalVolume =
      submarineState.hull.volume +
      submarineState.torpedos.volume +
      submarineState.cruiseMissiles.volume +
      submarineState.batteries.volume +
      volume +
      submarineState.propulsion.volume;

    setSubmarineState((prevState) => ({
      ...prevState,
      systems: {
        weight,
        volume,
        cost,
        complementAndStocksVolume,
        electricEquipmentWeight,
        accommodationVolume,
        stocksComplementWeight,
        gearSystemWeight,
        furnitureWeight,
        totalCost,
        enduranceDays,
        submergedTime,
        enduranceDistance,
        totalWeight,
        totalVolume,
      },
    }));
  };

  useEffect(() => {
    calculateSystems();
  }, [submarineState, setSubmarineState]);

  return { submarineState, calculateSystems };
};

export default useSystemsCalculator;
