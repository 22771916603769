import { useState } from "react";
import { useSubmarineContext } from "./Calculation/SubmarineContext"; // Import the provider
import Hull from "./Hull";
import Propulsion from "./Propulsion";
import Battery from "./Battery";
import Torpedo from "./Torpedo";
import CruiseMissile from "./CruiseMissile";
import Systems from "./Systems";
import Totals from "./Calculation/Totals";
import { Col, Offcanvas, Button, Row } from "react-bootstrap";

const Submarine = () => {
  const { submarineState } = useSubmarineContext();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="fixed-right" onClick={handleShow}>
        Open Debug Window
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop={false}
        style={{ width: "2000px" }}
      >
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Row>
          <Col>
            <Torpedo />
          </Col>
          <Col>
            <CruiseMissile />
          </Col>
          <Col>
            <Systems />
          </Col>
          <Col>
            <Battery />
          </Col>
          <Col>
            <Hull />
          </Col>
          <Col>
            <Propulsion />
          </Col>
        </Row>
        <Row>
          <Totals />
        </Row>
      </Offcanvas>
    </>
  );
};

export default Submarine;
