import React, { useState, startTransition } from "react";
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderLogo from "./assets/BMT logo (RGB Positive).jpg";
import "./App.css";

// Importing Pages and Components
import MissionProfiles from "./Pages/Missions/MissionProfiles";
import VesselBuilder from "./Pages/HullForm/VesselBuilder";
import VesselParameters from "./Pages/VesselParams/VesselParameters";
import VesselList from "./Pages/VesselList/VesselList";
import VesselListHeader from "./Pages/VesselList/VesselListHeader";
import VesselResults from "./Pages/VesselResults/VesselResults";
import SplashScreen from "./Pages/SplashScreen/SplashScreen";
import Submarine from "./components/Submarine/Submarine";

//Submarine Provider required as various contianed components will interact with this state
import {
  SubmarineProvider,
  useSubmarineContext,
} from "./components/Submarine/Calculation/SubmarineContext";

function App() {
  //Handling for Missions and Vessel list popup button funcionality
  const [leftWidth, setLeftWidth] = useState(50);

  const handleMouseMove = (e: MouseEvent) => {
    const newLeftWidth = (e.clientX / window.innerWidth) * 100;
    if (newLeftWidth > 10 && newLeftWidth < 90) {
      setLeftWidth(newLeftWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const { submarines } = useSubmarineContext();

  const [missionsToggle, setMissionsToggle] = useState(false);
  const [vesselsToggle, setVesselToggle] = useState(false);

  const handleMissionsOpen = () => setMissionsToggle(true);
  const handleMissionsClose = () => setMissionsToggle(false);

  const handleVesselsOpen = () => setVesselToggle(true);
  const handleVesselsClose = () => setVesselToggle(false);

  // Handling to display splash screen instead of main content, until user has clicked start
  const [isLoading, setIsLoading] = useState(true);
  const handleStart = () => {
    startTransition(() => {
      // startTransition used to avoid issues with the three/fiber loading 3D models
      setIsLoading(false);
    });
  };
  if (isLoading) {
    return <SplashScreen onStart={handleStart} />;
  }

  // Main app after loading completes
  return (
    <SubmarineProvider>
      <div className="">
        {/* Header */}
        <header className="">
          <a href="/" className="">
            <img src={HeaderLogo} alt="Logo" height="80" />
          </a>
        </header>

        {/* Resizable Grid Layout */}
        <div>
          <Button
            className="fixed-left"
            variant="primary"
            onClick={handleMissionsOpen}
          >
            Missions
          </Button>
          <Button
            className="fixed-bottom"
            variant="primary"
            onClick={handleVesselsOpen}
          >
            <VesselListHeader />
          </Button>
          <div>
            <Offcanvas
              className={"w-50"}
              show={missionsToggle}
              onHide={handleMissionsClose}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Mission Profiles</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <MissionProfiles />
              </Offcanvas.Body>
            </Offcanvas>

            <Container fluid>
              <Row style={{ marginLeft: "40px", marginRight: "0px" }}>
                <Col xs={3}>
                  <VesselParameters />
                </Col>
                <Col xs={6}>
                  <VesselBuilder />
                  <Submarine />
                </Col>
                <Col xs={3}>
                  <VesselResults />
                </Col>
              </Row>
            </Container>

            <Offcanvas
              className={"h-50"}
              show={vesselsToggle}
              onHide={handleVesselsClose}
              placement="bottom"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Vessels</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <VesselList />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </SubmarineProvider>
  );
}

export default App;
