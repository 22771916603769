import React, { useState, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Container,
  Alert,
} from "react-bootstrap";

// Define the props for the InputField component
interface InputFieldProps {
  id: string; // Unique identifier for the input field
  label: string; // Label text displayed beside the input field
  value?: number | string; // Value of the input field, can be a number or a string
  tooltip?: string; // Tooltip text that provides additional information
  unit?: string; // Optional unit to display after the input field
  readOnly?: boolean; // Optional boolean to determine if the input field is read-only
  formula?: string; // Optional formula to display when hovering over a calculated field
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Function to handle changes in the input field
  handleInputClick: (descriptionText: string) => void; // Function to handle click events on the input field
  min?: number; // Minimum value for validation
  max?: number; // Maximum value for validation
}

// InputField functional component
const InputField: React.FC<InputFieldProps> = ({
  id,
  label,
  value,
  tooltip,
  unit,
  readOnly = true,
  formula,
  handleInputChange,
  handleInputClick,
  min,
  max,
}) => {
  const [error, setError] = useState<string | null>(null);

  // Validation logic for the field
  const validate = (currentValue: number) => {
    if (min !== undefined && currentValue < min) {
      return `${label} must be at least ${min}.`;
    }
    if (max !== undefined && currentValue > max) {
      return `${label} must be at most ${max}.`;
    }

    return null;
  };

  // Validate on value change
  useEffect(() => {
    if (id === "name") {
      setError(null); // Skip validation for "name" id
      return;
    }
    const validationError = validate(value);
    setError(validationError);
  }, [value, id]);

  // Determine the formatted value for display
  const getFormattedValue = () => {
    if (value === null || value === undefined || value === "") return "0.00"; // Treat empty, null, or undefined as "0.00"
    if (typeof value === "number") return value.toFixed(2); // Format numbers to 2 decimal places
    return value; // For strings, return as is
  };

  // Determine dynamic styles for the input field
  const getInputStyle = () => {
    if (error) {
      return { borderColor: "red", backgroundColor: "#ffe6e6" }; // Red border and light red background
    }
    if (value !== undefined && !error) {
      return { borderColor: "green" }; // Green border for valid inputs
    }
    return {};
  };

  // Function to render the tooltip with optional formula for read-only fields
  const renderTooltip = (tooltipText: string, formula?: string) => (
    <Tooltip>
      {tooltipText}
      {readOnly &&
        formula && ( // For readonly fields, also print formula if it exists
          <Container>Formula: {formula}</Container>
        )}
    </Tooltip>
  );

  return (
    <div>
      <InputGroup>
        {/* Label with tooltip that provides additional information when hovered */}
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip(tooltip ?? "", formula)}
        >
          <InputGroup.Text style={{ width: "200px" }}>{label}</InputGroup.Text>
        </OverlayTrigger>

        {/* Input field with dynamic styles for validation feedback */}
        <FormControl
          type={
            id === "name"
              ? "text"
              : //: typeof value === "number"
                "number"
            //: "text"
          }
          id={id}
          value={getFormattedValue()} // Use formatted value
          onChange={(e) => {
            handleInputChange(e);
            const parsedValue =
              e.target.value === "" ? 0 : Number(e.target.value);
            setError(validate(parsedValue));
          }}
          onClick={() => handleInputClick(tooltip ?? "")}
          readOnly={readOnly}
          style={{
            ...getInputStyle(),
            ...(readOnly
              ? { backgroundColor: "#e9ecef", color: "#6c757d" }
              : {}),
          }}
        />

        {/* Optional unit text displayed after the input field */}
        {unit && (
          <InputGroup.Text style={{ width: "130px" }}>{unit}</InputGroup.Text>
        )}
      </InputGroup>

      {/* Display validation error */}
      {error && (
        <Alert variant="danger" className="mt-2">
          {error}
        </Alert>
      )}
    </div>
  );
};

export default InputField;
