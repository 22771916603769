import React from "react";
import { CardGroup, Card, Form, Container } from "react-bootstrap";
import { useSubmarineContext } from "../../components/Submarine/Calculation/SubmarineContext";

const VesselListHeader: React.FC = () => {
  const { submarines } = useSubmarineContext();

  return (
    <Container>
      <Form.Label>Vessels</Form.Label>
      <CardGroup>
        {submarines.length > 0 ? (
          submarines.map((submarine, index) => (
            <Card key={index}>
              <Card.Body>
                <Card.Title>
                  {" "}
                  {submarine.inputs.name || `Vessel ${index + 1}`}{" "}
                </Card.Title>
              </Card.Body>
            </Card>
          ))
        ) : (
          <Form.Label>No vessels created.</Form.Label>
        )}
      </CardGroup>
    </Container>
  );
};

export default VesselListHeader;
