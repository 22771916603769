// VesselBuilder.tsx
// A React component for visualizing and configuring a 3D vessel model.
import React, { useEffect, useState, useRef, Suspense } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
import { mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import * as THREE from "three";
import VesselControls from "./ControlsVessel";
import ModelControls from "./Controls3DModel";

import { useLengthDiameterContext } from "../../components/Submarine/Calculation/SubmarineContext";
import definition from "./VesselBuilder.json";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import DescriptionBox from "../../components/DescriptionBox";

// Model Imports
import nose_flat from "./models/nose_flat.obj";
import nose_elongated from "./models/nose_elongated.obj";
import conning_tall from "./models/conning_tall.obj";
import conning_short from "./models/conning_short.obj";
import stern_pentaform from "./models/stern_pentaform.obj";
import stern_cruciform from "./models/stern_cruciform.obj";
import stern_xplanes from "./models/stern_xplanes.obj";
import stern_invertedy from "./models/stern_invertedy.obj";
import bowplane_bridge from "./models/bowplane_bridge.obj";
import bowplane_eyebrow from "./models/bowplane_eyebrow.obj";
import bowplane_midline from "./models/bowplane_midline.obj";
import covert_ops from "./models/covert_ops.obj";

const VesselBuilder: React.FC = () => {
  // Vessel scaling
  const baseLengthMeters = 63;
  const baseWidthMeters = 8;
  const { length, diameter } = useLengthDiameterContext();
  const widthScaling = diameter / baseWidthMeters;
  const lengthScaling = length / baseLengthMeters;

  // 3D controls
  const orbitControlsRef = useRef<any>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera>(null);
  type ModelLoaderKey = keyof typeof modelLoaders;

  const modelLoaders = {
    nose_flat: useLoader(OBJLoader, nose_flat),
    nose_elongated: useLoader(OBJLoader, nose_elongated),
    conning_tall: useLoader(OBJLoader, conning_tall),
    conning_short: useLoader(OBJLoader, conning_short),
    stern_pentaform: useLoader(OBJLoader, stern_pentaform),
    stern_cruciform: useLoader(OBJLoader, stern_cruciform),
    stern_xplanes: useLoader(OBJLoader, stern_xplanes),
    stern_invertedy: useLoader(OBJLoader, stern_invertedy),
    bowplane_bridge: useLoader(OBJLoader, bowplane_bridge),
    bowplane_eyebrow: useLoader(OBJLoader, bowplane_eyebrow),
    bowplane_midline: useLoader(OBJLoader, bowplane_midline),
    covert_ops: useLoader(OBJLoader, covert_ops),
  };

  // Local State
  const [autoRotate, setAutoRotate] = useState(true);
  const [showWireframe, setShowWireframe] = useState(false);
  const [noseModelOption, setNoseModelOption] = useState("nose_flat");
  const [conningModelOption, setConningModelOption] = useState("conning_tall");
  const [tailModelOption, setTailModelOption] = useState("stern_pentaform");
  const [bowPlaneModelOption, setBowPlaneModelOption] =
    useState("bowplane_bridge");
  const [covertOpsModelOption, setCovertOpsModelOption] =
    useState("covert_ops");
  const [noseColor, setNoseColor] = useState("#ff0000");
  const [conningColor, setConningColor] = useState("#00ff00");
  const [tailColor, setTailColor] = useState("#0000ff");
  const [bowPlaneColor, setBowPlaneColor] = useState("#ffff00");
  const [covertOpsColor, setCovertOpsColor] = useState("#ff00ff");
  const [descriptionData, setDescriptionData] = useState({
    name: "",
    description: "",
    units: "",
  });

  // Update option description
  const handleOptionChange = (
    label: string,
    optionLabel: string,
    optionDescription: string
  ) => {
    setDescriptionData({
      name: `${label}: ${optionLabel}`,
      description: optionDescription,
      units: "",
    });
  };

  // Draw selected 3D objects
  const renderModel = (model: THREE.Group | null, color: string) => {
    if (!model) return null;
    return model.children.map((child, index) => (
      <mesh key={index} geometry={(child as THREE.Mesh).geometry}>
        <meshStandardMaterial color={color} wireframe={showWireframe} />
      </mesh>
    ));
  };

  // Download combined vessel model
  const handleDownload = () => {
    const chosenModels = [
      modelLoaders[noseModelOption as ModelLoaderKey],
      modelLoaders[conningModelOption as ModelLoaderKey],
      modelLoaders[tailModelOption as ModelLoaderKey],
      modelLoaders[bowPlaneModelOption as ModelLoaderKey],
      modelLoaders[covertOpsModelOption as ModelLoaderKey],
    ];

    const geometries: THREE.BufferGeometry[] = [];
    chosenModels.forEach((model) => {
      model.traverse((child: any) => {
        if (child.isMesh) {
          const geom = child.geometry.clone();
          // Apply scaling to match what's shown on the canvas
          geom.scale(widthScaling, widthScaling, lengthScaling);
          geometries.push(geom);
        }
      });
    });

    if (geometries.length === 0) return;

    const mergedGeometry = mergeGeometries(geometries);
    const mergedMesh = new THREE.Mesh(mergedGeometry);

    // Export using OBJExporter
    const exporter = new OBJExporter();
    const result = exporter.parse(mergedMesh);

    // Create a blob and trigger download
    const blob = new Blob([result], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "combined_model.obj";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Container>
      <Col className="d-flex flex-column">
        <DescriptionBox
          name={descriptionData.name}
          description={descriptionData.description}
          units={descriptionData.units}
        />

        <Row className="mb-4 flex-grow-1" style={{ height: "600px" }}>
          <div className="bg-light rounded" style={{ height: "100%" }}>
            <Canvas style={{ height: "100%" }}>
              <Suspense fallback={null}>
                <PerspectiveCamera
                  ref={cameraRef}
                  makeDefault
                  position={[25, 16, 14]}
                  fov={60}
                />
                <OrbitControls
                  ref={orbitControlsRef}
                  autoRotate={autoRotate}
                  autoRotateSpeed={1}
                  enableDamping
                  dampingFactor={0.1}
                  minDistance={10}
                  maxDistance={120}
                  target={[0, 0, 0]}
                />
                <ambientLight intensity={0.5} />
                <directionalLight position={[10, 10, 5]} intensity={1} />
                <group scale={[widthScaling, widthScaling, lengthScaling]}>
                  {renderModel(
                    modelLoaders[noseModelOption as ModelLoaderKey],
                    noseColor
                  )}
                  {renderModel(
                    modelLoaders[conningModelOption as ModelLoaderKey],
                    conningColor
                  )}
                  {renderModel(
                    modelLoaders[tailModelOption as ModelLoaderKey],
                    tailColor
                  )}
                  {renderModel(
                    modelLoaders[bowPlaneModelOption as ModelLoaderKey],
                    bowPlaneColor
                  )}
                  {renderModel(
                    modelLoaders[covertOpsModelOption as ModelLoaderKey],
                    covertOpsColor
                  )}
                </group>
              </Suspense>
            </Canvas>
          </div>
        </Row>

        {/* Combined Controls Component */}
        <Row className="mb-4 flex-shrink-0">
          <Col xs={9}>
            <div className="col-12 d-flex flex-row flex-wrap justify-content-start align-items-center bg-light p-3 rounded">
              {Object.entries(definition).map(([key, value]) => (
                <div className="me-4" key={key}>
                  <VesselControls
                    label={value.label}
                    color={
                      value.colorKey === "noseColor"
                        ? noseColor
                        : value.colorKey === "conningColor"
                        ? conningColor
                        : value.colorKey === "tailColor"
                        ? tailColor
                        : value.colorKey === "bowPlaneColor"
                        ? bowPlaneColor
                        : covertOpsColor
                    }
                    setColor={(color) =>
                      value.colorKey === "noseColor"
                        ? setNoseColor(color)
                        : value.colorKey === "conningColor"
                        ? setConningColor(color)
                        : value.colorKey === "tailColor"
                        ? setTailColor(color)
                        : value.colorKey === "bowPlaneColor"
                        ? setBowPlaneColor(color)
                        : setCovertOpsColor(color)
                    }
                    modelOption={
                      value.modelOptionKey === "noseModelOption"
                        ? noseModelOption
                        : value.modelOptionKey === "conningModelOption"
                        ? conningModelOption
                        : value.modelOptionKey === "tailModelOption"
                        ? tailModelOption
                        : value.modelOptionKey === "bowPlaneModelOption"
                        ? bowPlaneModelOption
                        : covertOpsModelOption
                    }
                    setModelOption={(option) =>
                      value.modelOptionKey === "noseModelOption"
                        ? setNoseModelOption(option)
                        : value.modelOptionKey === "conningModelOption"
                        ? setConningModelOption(option)
                        : value.modelOptionKey === "tailModelOption"
                        ? setTailModelOption(option)
                        : value.modelOptionKey === "bowPlaneModelOption"
                        ? setBowPlaneModelOption(option)
                        : setCovertOpsModelOption(option)
                    }
                    modelOptions={value.modelOptions}
                    onOptionChange={handleOptionChange}
                  />
                </div>
              ))}
            </div>
          </Col>

          <Col xs={3}>
            <ModelControls
              autoRotate={autoRotate}
              setAutoRotate={setAutoRotate}
              showWireframe={showWireframe}
              setShowWireframe={setShowWireframe}
              handleDownload={handleDownload}
            />
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default VesselBuilder;
