import React, { useState } from "react";
import { Bar, Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Card, Form, Stack, Button, Row, Col } from "react-bootstrap";
import { useSubmarineContext } from "../../components/Submarine/Calculation/SubmarineContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const VesselList: React.FC = () => {
  const { submarines, setSubmarines, setSubmarineState } =
    useSubmarineContext();
  const [highlightedVessel, setHighlightedVessel] = useState<number | null>(
    null
  );
  const [hiddenDatasets, setHiddenDatasets] = useState<Set<string>>(new Set());

  const handleDelete = (index: number) => {
    setSubmarines(submarines.filter((_, i) => i !== index));
  };

  const handleDeleteAll = () => {
    setSubmarines([]);
  };

  const handleLoadVessel = (index: number) => {
    setSubmarineState(submarines[index]);
  };

  const handleVesselClick = (index: number) => {
    setHighlightedVessel(index);
    handleLoadVessel(index);
  };

  const toggleDatasetVisibility = (label: string) => {
    setHiddenDatasets((prevHidden) => {
      const updatedHidden = new Set(prevHidden);
      if (updatedHidden.has(label)) {
        updatedHidden.delete(label);
      } else {
        updatedHidden.add(label);
      }
      return updatedHidden;
    });
  };

  const vesselNames = submarines.map(
    (sub) => sub.inputs.name || "Unnamed Vessel"
  );
  const vesselCosts = submarines.map((sub) => sub.systems.totalCost);
  const vesselCrew = submarines.map((sub) => sub.inputs.crewNum);
  const vesselWeight = submarines.map((sub) => sub.systems.totalWeight);
  const vesselDivingDepth = submarines.map((sub) => sub.inputs.divingDepth);

  const datasets = [
    {
      label: "Vessel Cost",
      data: vesselCosts,
      backgroundColor: vesselCosts.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 0.6)"
          : "rgba(75, 192, 192, 0.6)"
      ),
      borderColor: vesselCosts.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 1)"
          : "rgba(75, 192, 192, 1)"
      ),
      borderWidth: 1,
    },
    {
      label: "Crew Size",
      data: vesselCrew,
      backgroundColor: vesselCrew.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 0.6)"
          : "rgba(54, 162, 235, 0.6)"
      ),
      borderColor: vesselCrew.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 1)"
          : "rgba(54, 162, 235, 1)"
      ),
      borderWidth: 1,
    },
    {
      label: "Diving Depth",
      data: vesselDivingDepth,
      backgroundColor: vesselDivingDepth.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 0.6)"
          : "rgba(153, 102, 255, 0.6)"
      ),
      borderColor: vesselDivingDepth.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 1)"
          : "rgba(153, 102, 255, 1)"
      ),
      borderWidth: 1,
    },
    {
      label: "Total Weight",
      data: vesselWeight,
      backgroundColor: vesselWeight.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 0.6)"
          : "rgba(255, 206, 86, 0.6)"
      ),
      borderColor: vesselWeight.map((_, index) =>
        index === highlightedVessel
          ? "rgba(255, 99, 132, 1)"
          : "rgba(255, 206, 86, 1)"
      ),
      borderWidth: 1,
    },
  ];

  const filteredDatasets = datasets.filter(
    (dataset) => !hiddenDatasets.has(dataset.label)
  );

  const data = {
    labels: vesselNames,
    datasets: filteredDatasets,
  };

  const handleChartClick = (event: any, elements: any[]) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      setHighlightedVessel(elementIndex);
      handleLoadVessel(elementIndex);
    }
  };

  return (
    <Stack>
      {submarines.length === 0 ? (
        <Form.Label>No vessels have been created yet.</Form.Label>
      ) : (
        <Row>
          {/* Bar Chart Section */}
          <Col md={5}>
            <Bar
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                onClick: (event, elements) => handleChartClick(event, elements),
              }}
            />
          </Col>

          {/* Radar Chart Section */}
          <Col md={5}>
            <Radar
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </Col>

          {/* Vessel List Section */}
          <Col md={2}>
            <Button onClick={handleDeleteAll}>Delete All Vessels</Button>
            {submarines.map((submarine, index) => (
              <Stack
                key={index}
                onClick={() => handleVesselClick(index)}
                style={{ cursor: "pointer" }}
              >
                <Card>
                  <Card.Body>
                    <Card.Title>
                      {submarine.inputs.name || `Vessel ${index + 1}`}
                    </Card.Title>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Stack>
            ))}
          </Col>
        </Row>
      )}
    </Stack>
  );
};

export default VesselList;
