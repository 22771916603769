// Updated TorpedoCalculator.tsx
import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const useTorpedoCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const calculateTorpedo = () => {
    const { torpedoTubes } = submarineState.inputs;

    // Weight Calculation
    const baseWeight = 1.2 * torpedoTubes * 8; // Base weight per tube
    const structuralWeight = (1.2 + 0.4 + 1.5) * torpedoTubes * 3 * 1.5; // Structural weight
    const miscellaneousWeight = 20; // Fixed additional weight
    const weight = baseWeight + structuralWeight + miscellaneousWeight;

    // Volume Calculation
    const volumeBase = 124.86 * torpedoTubes;
    const volumeOffset = 10; // Fixed volume offset
    const volume = volumeBase + volumeOffset;

    // Cost Calculation
    let cost = 0;
    let costBase = 0;
    let costPerTube = 0;

    if (torpedoTubes === 0) {
      cost = 0;
      costBase = 0;
      costPerTube = 0;
    } else if (torpedoTubes < 6) {
      costBase = 4;
      costPerTube = 2;
      cost = costBase + costPerTube * torpedoTubes;
    } else {
      costBase = 10;
      costPerTube = 3;
      cost = costBase + costPerTube * torpedoTubes;
    }

    const totalTorpedoes = torpedoTubes;

    // Update submarine state with torpedo details
    setSubmarineState((prevState) => ({
      ...prevState,
      torpedos: {
        baseWeight,
        structuralWeight,
        miscellaneousWeight,
        weight,
        volume,
        cost,
        totalTorpedoes,
        volumeBase,
        volumeOffset,
        costBase,
        costPerTube,
      },
    }));
  };

  useEffect(() => {
    calculateTorpedo();
  }, [submarineState.inputs, setSubmarineState]);

  return { submarineState, calculateTorpedo };
};

export default useTorpedoCalculator;
