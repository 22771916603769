import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  Alert,
} from "react-bootstrap";
import InputField from "../../components/InputField";
import DescriptionBox from "../../components/DescriptionBox";
import { useSubmarineContext } from "../../components/Submarine/Calculation/SubmarineContext"; // Import the context hook

// Import configuration files for field definitions
import inputsConfigData from "./FieldDefinitions/inputs.json";
import keyCharacteristicsData from "./FieldDefinitions/outputs.json";
import useTorpedoCalculator from "../../components/Submarine/Calculation/TorpedoCalculator";
import useCruiseCalculator from "../../components/Submarine/Calculation/CruiseMissileCalculator";
import useHullCalculator from "../../components/Submarine/Calculation/HullCalculator";
import usePropulsionCalculator from "../../components/Submarine/Calculation/PropulsionCalculator";
import useSystemsCalculator from "../../components/Submarine/Calculation/SystemsCalculator";
import useBatteryCalculator from "../../components/Submarine/Calculation/BatteryCalculator";

interface VesselFormData {
  [key: string]: any;
}

type FieldClass =
  | "inputs"
  | "hull"
  | "propulsionOutputs"
  | "systemOutputs"
  | "cruiseMissileOutputs"
  | "torpedoOutputs"
  | "batteries";

interface FieldConfig {
  id: string;
  label: string;
  tooltip?: string;
  unit?: string;
  readOnly?: boolean;
  formula?: string; // Mathematical expression for calculated fields
  min?: number;
  max?: number;
  defaultValue?: any;
  class: FieldClass; // Added 'class' to specify the type of the field
}

const VesselParameters: React.FC = () => {
  const { submarineState, setSubmarineState, submarines, setSubmarines } =
    useSubmarineContext();

  const { calculateTorpedo } = useTorpedoCalculator();
  const { calculateCruiseMissile } = useCruiseCalculator();
  const { calculateHull } = useHullCalculator();
  const { calculatePropulsion } = usePropulsionCalculator();
  const { calculateSystems } = useSystemsCalculator();
  const { calculateBattery } = useBatteryCalculator();

  // Typecast imported JSON to FieldConfig[]
  const inputsConfig: FieldConfig[] = inputsConfigData as FieldConfig[];
  const keyCharacteristics: FieldConfig[] =
    keyCharacteristicsData as FieldConfig[];

  // Local state to manage description, error messages, and vessels list
  const [descriptionData, setDescriptionData] = useState<{
    name: string;
    description: string;
    units?: string;
  }>({
    name: "",
    description: "",
    units: undefined,
  });

  const [error, setError] = useState<string>("");

  // Combine both configuration objects into a single array
  const allConfigs: FieldConfig[] = [...inputsConfig, ...keyCharacteristics];

  // Update state and validate data
  const updateSubmarineInputs = (updatedData: VesselFormData) => {
    // Update state with new data
    setSubmarineState((prevSubmarineState) => ({
      ...prevSubmarineState,
      inputs: {
        ...prevSubmarineState.inputs,
        ...updatedData,
      },
    }));

    calculateTorpedo();
    calculateCruiseMissile();
    calculateHull();
    calculateSystems();
    calculateBattery();
    calculatePropulsion();
  };

  // Handle input value change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    let updatedData: VesselFormData = {
      ...submarineState,
      [id]: id === "name" ? value : value === "" ? 0 : Number(value),
    };

    // Validate and update state
    updateSubmarineInputs(updatedData);
  };

  // Handle input field click to display field description
  const handleInputClick = (field: FieldConfig) => {
    setDescriptionData({
      name: field.label,
      description: field.tooltip || "No description available.",
      units: field.unit,
    });
  };

  // Randomize values within min and max range for each field
  const randomizeValues = (): void => {
    const randomizedData: Partial<VesselFormData> = {};

    allConfigs.forEach((field) => {
      if (field.id === "name") {
        randomizedData[field.id] = getRandomVesselName();
      } else if (
        typeof field.min !== "undefined" &&
        typeof field.max !== "undefined"
      ) {
        randomizedData[field.id] = getRandomInt(field.min, field.max);
      } else if (field.defaultValue !== undefined) {
        randomizedData[field.id] = field.defaultValue;
      }
    });

    // Update state and validate
    updateSubmarineInputs(randomizedData);
  };

  // Set default values for each field
  const setDefaultValues = (): void => {
    const defaultData: Partial<VesselFormData> = {};

    allConfigs.forEach((field) => {
      if (field.defaultValue !== undefined) {
        defaultData[field.id] = field.defaultValue;
      }
    });

    // Update state and validate
    updateSubmarineInputs(defaultData);
  };

  // Render input fields for a section
  const renderSection = (title: string, config: FieldConfig[]) => (
    <>
      <Form.Label>{title}</Form.Label>
      {config.map((field) => {
        const section =
          submarineState?.[field.class as keyof typeof submarineState];
        let value =
          section && typeof section === "object"
            ? section[field.id as keyof typeof section]
            : undefined;

        return (
          <InputField
            key={field.id}
            id={field.id}
            label={field.label}
            value={value || ""}
            tooltip={field.tooltip}
            unit={field.unit}
            readOnly={field.readOnly}
            formula={field.formula}
            handleInputChange={handleInputChange}
            handleInputClick={() => handleInputClick(field)}
            min={field.min}
            max={field.max}
          />
        );
      })}
    </>
  );

  // Helper functions
  const getRandomInt = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getRandomVesselName = (): string => {
    const names = [
      "Voyager",
      "Odyssey",
      "Neptune",
      "Nautilus",
      "Endeavour",
      "Aurora",
      "Discovery",
      "Atlantis",
      "Pioneer",
      "Horizon",
    ];
    return names[Math.floor(Math.random() * names.length)];
  };

  // Main component rendering
  return (
    <Container>
      <Col className="d-flex flex-column" style={{ height: "85vh" }}>
        {/* Display field description */}
        <DescriptionBox
          name={descriptionData.name}
          description={descriptionData.description}
          units={descriptionData.units}
        />

        {/* Input fields and key features sections */}
        <Form
          className="p-4 bg-light rounded flex-grow-1"
          style={{ overflowY: "scroll" }}
        >
          <Form.Group>{renderSection("Input Fields", inputsConfig)}</Form.Group>
          <Form.Group>
            {renderSection("Key Features", keyCharacteristics)}
          </Form.Group>
        </Form>

        {/* Display error messages if available */}
        {error && (
          <Row className="mt-4">
            <Col>
              <Alert variant="danger">
                <strong>Error:</strong> {error}
              </Alert>
            </Col>
          </Row>
        )}

        {/* Action buttons section */}
        <Row className="flex-shrink-0" style={{ marginTop: "20px" }}>
          <ButtonGroup>
            <Button variant="primary" onClick={randomizeValues}>
              Randomize
            </Button>
            <Button variant="secondary" onClick={setDefaultValues}>
              Default
            </Button>
            <Button
              variant="success"
              onClick={() => setSubmarines([...submarines, submarineState])}
            >
              Add Vessel
            </Button>
          </ButtonGroup>
        </Row>
      </Col>
    </Container>
  );
};

export default VesselParameters;
