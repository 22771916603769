import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const usePropulsionCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();
  const { crewNum, maxSubmergedSpeed, indiscretionRatio } =
    submarineState.inputs;

  const calculatePropulsion = () => {
    const { lengthDiameterRatio, displacement, normDisplacement } =
      submarineState.hull;
    const enduranceDays = submarineState.systems.enduranceDays;

    // Drag Coefficient
    const dragCoefficient =
      lengthDiameterRatio < 6
        ? 10.5 / (lengthDiameterRatio + 3)
        : -22 / (lengthDiameterRatio - 25);

    // Admiralty Coefficient
    const admiraltyValue = 22 * maxSubmergedSpeed - 102;

    // Max Power
    const maxPower =
      (maxSubmergedSpeed ** 3 *
        (2356 * Math.log(crewNum + 1) - 6300) ** (2 / 3) *
        dragCoefficient *
        0.75) /
      admiraltyValue;

    // Patrol Speed and Power
    const patrolSpeed =
      submarineState.inputs.transitPatrolDistance /
      (submarineState.inputs.transitPatrolDays * 24);
    const patrolPower =
      (patrolSpeed ** 3 *
        (2356 * Math.log(crewNum + 1) - 6300) ** (2 / 3) *
        dragCoefficient *
        0.8) /
      admiraltyValue;

    // Hotel Load and Energy
    const hotelLoad = (45 * displacement) / 1000;
    const hotelLEnergyValue = hotelLoad * enduranceDays * 24 * 60 * 60;

    // Battery Life and Submerged Endurance
    const batteryLife = 8 * (100 / indiscretionRatio - 1);
    const submergedEndurance = batteryLife / 24;

    // Power Plant and Other Weights
    const powerPlantWeight = 98 * maxPower;
    const dieselEngineWeight = (0.394 * powerPlantWeight) / 1000;
    const electricPropulsionWeight = (0.226 * powerPlantWeight) / 1000;
    const shaftingWeight = (0.124 * powerPlantWeight) / 1000;
    const foundationsWeight = 0.023 * (normDisplacement + maxPower);

    const initialVolumeEstimate =
      (submarineState.hull.displacement * 1000) / 1024;

    // Volumes
    const dieselPlantVolume =
      ((55 / 1000) *
        maxSubmergedSpeed ** 3 *
        (0.8 * initialVolumeEstimate) ** (2 / 3)) /
      admiraltyValue;
    const electricMotorVolume =
      ((43 / 1000) *
        maxSubmergedSpeed ** 3 *
        (0.8 * initialVolumeEstimate) ** (2 / 3)) /
      admiraltyValue;

    // Cost
    const cost = (maxPower / 10) * 0.2;

    // Average Speed Calculation
    const totalDistance =
      submarineState.inputs.transitToPatrolDistance +
      submarineState.inputs.transitBackToBaseDistance;
    const totalTime =
      (submarineState.inputs.transitBackToBaseDays +
        submarineState.inputs.transitToPatrolDays) *
      24;
    const averageSpeed = totalDistance / totalTime;

    // Fuel and Oil Calculations
    let fuelAndOilWeight =
      (((maxPower * averageSpeed) / (maxSubmergedSpeed * 0.514444)) *
        enduranceDays *
        24 *
        60 *
        60) /
        45000000 +
      hotelLEnergyValue / 45000000;
    const fuelAndOilVolume = fuelAndOilWeight / 0.85;

    fuelAndOilWeight = fuelAndOilWeight / 2.5; // artificial sclaing to approximate original

    // Total Volume
    const volume = dieselPlantVolume + electricMotorVolume + fuelAndOilVolume;

    let weight =
      fuelAndOilWeight +
      dieselEngineWeight +
      electricPropulsionWeight +
      shaftingWeight +
      foundationsWeight;

    weight = weight * 2.5; // artificial sclaing to approximate original

    // Update state if required changes are detected
    setSubmarineState((prevState) => {
      if (
        prevState.propulsion &&
        prevState.propulsion.volume === volume &&
        prevState.propulsion.weight === weight
      ) {
        return prevState; // No update necessary
      }
      return {
        ...prevState,
        propulsion: {
          maxPower,
          patrolPower,
          submergedEndurance,
          volume,
          cost,
          dieselPlantVolume,
          electricMotorVolume,
          fuelAndOilVolume,
          fuelAndOilWeight,
          dieselEngineWeight,
          electricPropulsionWeight,
          shaftingWeight,
          foundationsWeight,
          hotelLoad,
          averageSpeed,
          patrolSpeed,
          weight,
        },
      };
    });
  };

  useEffect(() => {
    calculatePropulsion();
  }, [
    submarineState.inputs,
    submarineState.hull,
    submarineState.systems.enduranceDays,
    setSubmarineState,
  ]);

  return { submarineState, calculatePropulsion };
};

export default usePropulsionCalculator;
