import { useEffect } from "react";
import { useSubmarineContext } from "./SubmarineContext";

const useCruiseMissileCalculator = () => {
  const { submarineState, setSubmarineState } = useSubmarineContext();

  const calculateCruiseMissile = () => {
    const missileCount = submarineState.inputs.verticalLaunchSilos;
    const diameter = submarineState.hull.diameter;

    // Assume 4 missiles per silo
    const numSilo = missileCount / 4;

    // Structural support for silos
    const siloSupport = 7 * (Math.PI / 4) * (0.75 ** 2 - 0.7 ** 2) * 8;

    // Plate structure around silos
    const plateStructure =
      ((diameter / 2) ** 2 * Math.atan(1.5 / (diameter / 2 - 1)) -
        1.5 * (diameter / 2 - 1)) *
      0.05 *
      8;

    // Cap structure for silos
    const capStructure = Math.PI * (0.75 / 2) ** 2 * 0.3 * 8 + 0.3;

    // Mass Calculation
    const weight =
      numSilo *
        (siloSupport + plateStructure + 0.2 + 1.25 * capStructure + 1.4 + 0.5) +
      plateStructure;

    // Volume Calculation
    const volumeBase = ((Math.PI * diameter ** 2) / 4) * 0.75 * numSilo;
    const volumeOffset = (Math.PI * diameter ** 2) / 4;

    const volume = ((Math.PI * diameter ** 2) / 4) * (0.75 * numSilo + 1);

    // Cost Calculation
    let cost = 0;
    if (numSilo === 0) {
      cost = 0;
    } else {
      cost =
        (siloSupport + plateStructure + capStructure) * numSilo +
        plateStructure +
        3.5 +
        2 * numSilo;
    }

    // Update submarine state with cruise missile details
    setSubmarineState((prevState) => ({
      ...prevState,
      cruiseMissiles: {
        weight,
        volume,
        cost,
        missileCount,
        siloSupport,
        plateStructure,
        capStructure,
        numSilo,
        volumeBase,
        volumeOffset,
      },
    }));
  };

  useEffect(() => {
    calculateCruiseMissile();
  }, [submarineState.inputs, submarineState.hull, setSubmarineState]);

  return { submarineState, calculateCruiseMissile };
};

export default useCruiseMissileCalculator;
