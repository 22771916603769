import React, { useState } from 'react';
import { Container, Form, Button, Image, Card } from 'react-bootstrap';
import HeaderLogo from './Splash_Image.jpg';

interface SplashScreenProps {
  onStart: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onStart }) => {
  const [inputValue, setInputValue] = useState('password');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleStartClick = () => {
    if (inputValue === 'password') {
      onStart();
    }
    // You could add an alert or feedback message here if the incorrect password is entered.
  };

  const isCorrectPassword = inputValue === 'password';

  return (
    <Container fluid className="d-flex vh-100 flex-column justify-content-center align-items-center bg-light text-center">
      <Card className="p-4 shadow-lg" style={{ maxWidth: '500px', width: '100%' }}>
        <Image src={HeaderLogo} alt="Logo" height="100" className="mb-4" />
        <Card.Body>
          <Card.Title className="mb-3">Welcome to the Vessel Design App</Card.Title>
          <Card.Text className="mb-4">
            This application helps you design and configure submarines for specific missions. You can adjust parameters, customize hull forms, and evaluate results.
          </Card.Text>
          <Form.Group controlId="formPassword">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={inputValue}
              onChange={handleChange}
              className="mb-3"
            />
          </Form.Group>
          <Button
            onClick={handleStartClick}
            disabled={!isCorrectPassword}
            variant={isCorrectPassword ? 'primary' : 'secondary'}
            className="px-4"
          >
            Start
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SplashScreen;
