import React from "react";
import { useSubmarineContext } from "./Calculation/SubmarineContext";
import ClassDisplay from "../ClassDisplay";
import usePropulsionCalculator from "./Calculation/PropulsionCalculator";

const Propulsion: React.FC = () => {
  const { submarineState } = usePropulsionCalculator();

  const sections = [
    {
      title: "Weight",
      value: submarineState.propulsion?.weight?.toFixed(2) || "N/A",
      equation:
        "0.744 * powerPlantWeightValue / 1000 + fuelandOilWeightValue + batteryWeightValue + dieselEnginePlantWeightValue + electricPropulsionPlantWeightValue + shaftingWeightValue + foundationsWeightValue",
      parameters: [
        {
          label: "Fuel and Oil Weight",
          value:
            submarineState.propulsion?.fuelAndOilWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Diesel Engine Weight",
          value:
            submarineState.propulsion?.dieselEngineWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Electric Propulsion Weight",
          value:
            submarineState.propulsion?.electricPropulsionWeight?.toFixed(2) ||
            "N/A",
        },
        {
          label: "Shafting Weight",
          value: submarineState.propulsion?.shaftingWeight?.toFixed(2) || "N/A",
        },
        {
          label: "Foundations Weight",
          value:
            submarineState.propulsion?.foundationsWeight?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Volume",
      value: submarineState.propulsion?.volume?.toFixed(2) || "N/A",
      equation: "dieselPlantVolume + electricMotorVolume + fuelAndOilVolume",
      parameters: [
        {
          label: "Diesel Plant Volume",
          value:
            submarineState.propulsion?.dieselPlantVolume?.toFixed(2) || "N/A",
        },
        {
          label: "Electric Motor Volume",
          value:
            submarineState.propulsion?.electricMotorVolume?.toFixed(2) || "N/A",
        },
        {
          label: "Fuel and Oil Volume",
          value:
            submarineState.propulsion?.fuelAndOilVolume?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Cost",
      value: submarineState.propulsion?.cost?.toFixed(2) || "N/A",
      equation: "(maxPower / 10) * 0.2",
      parameters: [
        {
          label: "Max Power",
          value: submarineState.propulsion?.maxPower?.toFixed(2) || "N/A",
        },
      ],
    },
    {
      title: "Max Power",
      value: submarineState.propulsion?.maxPower?.toFixed(2) || "N/A",
      equation:
        "(maxSubmergedSpeed ** 3 * (2356 * Math.log(crewNum + 1) - 6300) ** (2 / 3) * dragCoefficient * 0.75) / admiraltyValue",
      parameters: [
        {
          label: "Patrol Power",
          value: submarineState.propulsion?.patrolPower?.toFixed(2) || "N/A",
        },
        {
          label: "Average Speed",
          value: submarineState.propulsion?.averageSpeed?.toFixed(2) || "N/A",
        },
        {
          label: "Submerged Endurance",
          value:
            submarineState.propulsion?.submergedEndurance?.toFixed(2) || "N/A",
        },
        {
          label: "Hotel Load",
          value: submarineState.propulsion?.hotelLoad?.toFixed(2) || "N/A",
        },
      ],
    },
  ];

  return <ClassDisplay title="Propulsion System" sections={sections} />;
};

export default Propulsion;
